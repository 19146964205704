import { Dialog, Transition } from '@headlessui/react'
import { Check } from 'lucide-react'
import { Fragment, useEffect, useRef } from 'react'
import { useLocation, NavLink } from 'react-router-dom';
import { CHANGE_PASSWORD, LOGIN_ROUTE } from '../../../../utils/consts';

export default function AccessAlert({ open, setOpen, alertTitle, func = null }) {
	const cancelButtonRef = useRef(null)
	const isChangePasswordRoute = useLocation().pathname.includes(CHANGE_PASSWORD)

	useEffect(() => {
		const onPopState = (e) => {
			e.preventDefault();
			setOpen(false)
		};
		window.history.pushState(null, "", window.location.pathname);
		window.addEventListener("popstate", onPopState);
		return () => {
			window.removeEventListener("popstate", onPopState);
		};
	}, [])

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as='div' className='relative z-10' initialFocus={cancelButtonRef} onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
				</Transition.Child>

				<div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
							enterTo='opacity-100 translate-y-0 sm:scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 translate-y-0 sm:scale-100'
							leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
							<Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg'>
								<div className='bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
									<div className='flex items-center'>
										<div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-300 sm:mx-0 sm:h-10 sm:w-10'>
											<Check className='h-6 w-6 text-green-900' aria-hidden='true' />
										</div>
										<div className='text-center ml-4 sm:mt-0 sm:text-left'>
											<Dialog.Title
												as='h3'
												className='text-base font-semibold leading-6 text-gray-900'>
												{alertTitle}
											</Dialog.Title>
										</div>
									</div>
								</div>
								<div className='bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
									{
										isChangePasswordRoute ? 
										<NavLink
											to={LOGIN_ROUTE}
											className='inline-flex w-full justify-center rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent/90 sm:ml-3 sm:w-auto'>
											Продолжить
										</NavLink>
										:
										<button
											type='button'
											className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
											onClick={
												func !== null ?
												func :
												() => setOpen(false)
											}
											ref={cancelButtonRef}>
											Продолжить
										</button>
									}
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
