import { useEffect, useState } from "react";
import { VALIDATE_STYLE } from "../consts";

export const useScreenSize = (screenWidth) => {
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < screenWidth);

  useEffect(() => {
    let prevIsMobile = isMobileScreen;

    const handleResize = () => {
      const currentIsMobile = window.innerWidth < screenWidth;
      if (currentIsMobile !== prevIsMobile) {
        setIsMobileScreen(currentIsMobile);
        prevIsMobile = currentIsMobile;
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileScreen]);

  return isMobileScreen;
};

export const useCityValidate = (isFieldPreparing) => {
  const [isInputChanged, setIsInputChanged] = useState(false)
  const [isCutySelected, setIsСitySelected] = useState(false)
  const [cityValidateStyle, setCityValidateStyle] = useState({})
  
  const changeValidateStyle = () => {
    console.log('HI')
    if (!isFieldPreparing) {
      if (isInputChanged) {
        setCityValidateStyle(VALIDATE_STYLE)
      } else if (!isCutySelected) {
        setCityValidateStyle(VALIDATE_STYLE)
      } else {
        setCityValidateStyle({})
      }
    }
  }

  useEffect(() => {
    if (!(isFieldPreparing && !isInputChanged && !isCutySelected)) {
      changeValidateStyle()
    }
  }, [isFieldPreparing, isInputChanged, isCutySelected])

  return [cityValidateStyle, setIsInputChanged, setIsСitySelected]
}
