export const showNotification = (title, options = {}) => {
  if (!("Notification" in window)) {
    return;
  }

  if (Notification.permission === "granted") {
    navigator.serviceWorker.getRegistration().then(function(registration) {
      if (registration) {
        registration.showNotification(title, options);
      } else {
        console.log("Сервисный воркер не зарегистрирован.");
      }
    }).catch(err => {
      console.log("Ошибка при получении регистрации сервисного воркера:", err);
    });
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(permission => {
      if (permission === "granted") {
        navigator.serviceWorker.getRegistration().then(function(registration) {
          if (registration) {
            registration.showNotification(title, options);
          } else {
            console.log("Сервисный воркер не зарегистрирован.");
          }
        }).catch(err => {
          console.log("Ошибка при получении регистрации сервисного воркера:", err);
        });
      }
    });
  }
};