export const handleAddPhoto = (event, photos, setPhotos, setImage) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      const newPhotos = [...photos];
      const newPhotosNames = newPhotos.map((image) => image.name)
      
      for (let i = 0; i < files.length; i += 1) {
          if(newPhotos.length < 8) {
              if(!newPhotosNames.includes(files[i].name)) {
                  newPhotos.push(files[i])
                  newPhotosNames.push(files[i].name)
              }
          } else break
      }

      const processedPhotos = [];
      const promises = newPhotos.map(
        (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64String = reader.result
                .replace("data:", "")
                .replace(/^.+,/, ""); // Extract base64 content
              processedPhotos.push(base64String);
              resolve(); // Resolve the promise after processing
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          })
      );

      Promise.all(promises)
        .then(() => {
          setPhotos(newPhotos);
          setImage(processedPhotos);
        })
        .catch((error) => {
          console.error("Error processing files:", error);
        });
    }
  }; 

  export const handleDrop = (event, photos, setPhotos, setImage) => {
    event.preventDefault();
    if (event.dataTransfer.files) {
      event.preventDefault();
      const files = Array.from(event.dataTransfer.files);
      const newPhotos = [...photos];
      const newPhotosNames = newPhotos.map((image) => image.name)
      
      for (let i = 0; i < files.length; i += 1) {
          if(newPhotos.length < 8) {
              if(!newPhotosNames.includes(files[i].name)) {
                  newPhotos.push(files[i])
                  newPhotosNames.push(files[i].name)
              }
          } else break
      }

      const processedPhotos = [];
      const promises = newPhotos.map(
        (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64String = reader.result
                .replace("data:", "")
                .replace(/^.+,/, ""); // Extract base64 content
              processedPhotos.push(base64String);
              resolve(); // Resolve the promise after processing
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          })
      );

      Promise.all(promises)
        .then(() => {
          setPhotos(newPhotos);
          setImage(processedPhotos);
        })
        .catch((error) => {
          console.error("Error processing files:", error);
        });
    }
  };
