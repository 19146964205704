import { Dialog, Transition } from '@headlessui/react'
import { AtSign, AtomIcon, Home, Phone } from 'lucide-react'
import { observer } from 'mobx-react-lite'
import React, {Fragment, useContext, useEffect, useRef, useState} from 'react'
import {check, handleUpdateBusinessProfile, handleUpdateProfile, sendSms} from '../../http/userAPI'
import Field from '../ui/Field/Field'
import AccessAlert from '../ui/modal/AccessAlert/AccessAlert'
import ErrorAlert from '../ui/modal/ErrorAlert/ErrorAlert'
import {fetchRegions} from "../../http/locationAPI";
import {Context} from "../../index";
import PhoneVerification from "../ui/modal/PhoneVerification/PhoneVerification";
import { BUSINESS_ROUTE, VALIDATE_STYLE } from '../../utils/consts'
import { useCityValidate, useScreenSize } from '../../utils/hooks/hooks'
import { NavLink } from 'react-router-dom'
import styles from "../ui/ShopDeviceList/ShopDeviceList.module.scss";

const ProfileUser = observer(({show, onHide}) => {
	const { location } = useContext(Context)
	const cancelButtonRef = useRef(null)
	const [errorAlertShow, setErrorAlertShow] = useState(false)
	const [accessAlertShow, setAccessAlertShow] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [alertTitle, setAlertTitle] = useState('')
	const [errorStatus, setErrorStatus] = useState('')
	const [jwtToken, setJwtToken] = useState(null)
	const [verificationShow, setVerificationShow] = useState(false)
	const [verificationId, setVerificationId] = useState('')

	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [city, setCity] = useState('')
	const [cityRepresentation, setCityRepresentation] = useState('')
	const [phone, setPhone] = useState('')
	const [isPhoneVisible, setIsPhoneVisible] = useState(true)
	const [confirmedPhone, setConfirmedPhone] = useState('')
	const [isFieldPreparing, setIsFieldPreparing] = useState(true);

	const [inn, setInn] = useState('')
	const [ogrn, setOgrn] = useState('')
	const [companyName, setCompanyName] = useState('')
	const [legalAddress, setLegalAddress] = useState('')
	const [isLegal, setIsLegal] = useState(false)

	const [isProfileShown, setIsProfileShown] = useState(true);

	const isTabletScreen = useScreenSize('555')
	const isMobileScreen = useScreenSize('380')
	const [cityValidateStyle, setIsInputChanged, setIsСitySelected] = useCityValidate(isFieldPreparing)
	const [lastSelectedCity, setLastSelectedCity] = useState('')

	useEffect(() => {
		fetchRegions({name: cityRepresentation}).then(data => {
			location.setLocations([...(data[0] ? [data[0]] : []), ...(data[1] ? [data[1]] : [])])
		})
	}, [cityRepresentation, location])

	useEffect(() => {
		if (show) {
			check().then(res => {
				let data = res?.data;
				if (data) {
					setName(data.name)
					setEmail(data.email)
					setCity(data.city.id)
					setCityRepresentation(data.city.region.name + ", " + data.city.name)
					let phone = data.phone.replace("+7", "+7 ").replace(")", ") ")
					setConfirmedPhone(phone)
					console.log('data?.data?.is_phone_visible', data?.is_phone_visible);
					console.log('data', data);
					setIsPhoneVisible(data?.is_phone_visible)
					setPhone(phone)
					setIsСitySelected(true)
					setLastSelectedCity(data.city.region.name + ", " + data.city.name)
				}
				data = data.business_profile;
				if (data) {
					setIsLegal(true)
					setInn(data.inn)
					setOgrn(data.ogrn)
					setLegalAddress(data.legal_address)
					setCompanyName(data.company_name)
				}
			})
		}
	}, [show])

	const smsVerification = async e => {
		try{
			const response = await sendSms(phone.replace(/\s/g, ''))
			setVerificationId(response.data.verification_id)
			setVerificationShow(true)
		}
		catch(error){
			console.error(error)
			setAlertMessage(error.response)
			setErrorStatus(error.response?.status)
			setAlertTitle("Ошибка отправки смс")
			setErrorAlertShow(true)
		}
	}

	async function updateProfile(token) {
		let updateData = handleUpdateProfile
		setJwtToken(token)
		setConfirmedPhone(phone)
		let data = [name, city, isPhoneVisible, token]
		if (!isProfileShown && isLegal) {
			updateData = handleUpdateBusinessProfile
			data = [inn, ogrn, legalAddress, companyName]
		}

		if (name && phone && !Object.keys(cityValidateStyle).length && cityRepresentation) {
			try {
				await updateData(...data)
				setPhone(phone)
				setAlertTitle('Вы успешно обновили информацию')
				setAccessAlertShow(true)
			} catch (error) {
				setAlertMessage(error.response)
				setErrorStatus(error.response?.status)
				setAlertTitle('Ошибка при обновлении информации')
				setErrorAlertShow(true)
			}
		}

	}

	useEffect(() => {
		const onPopState = (e) => {
			e.preventDefault();
		    setIsFieldPreparing(true)
		    onHide(false)
		};
		window.history.pushState(null, "", window.location.pathname);
		window.addEventListener("popstate", onPopState);
		return () => {
		    window.removeEventListener("popstate", onPopState);
		};
	}, []);

	return (
		<>
			<Transition.Root show={show} as={Fragment}>
				<Dialog
					as='div'
					className='relative z-10'
					initialFocus={cancelButtonRef}
					onClose={() => setAccessAlertShow(false)}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
					</Transition.Child>

					<div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
						<div
							className='flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0'
							onClick={() => onHide(false)}>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
								enterTo='opacity-100 translate-y-0 sm:scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 translate-y-0 sm:scale-100'
								leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
								<Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full max-w-xl'>
									<div className='bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
										<div className='sm:flex sm:items-start'>
											<div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full'>
												<Dialog.Title as='h3' className='text-lg font-bold leading-6 text-gray-900'>
													Мой профиль
												</Dialog.Title>
												<div className='mt-6'>
													<form className='w-full'>
														{
															isLegal ? (
																<button
																	className={`${styles.customButton} ${styles.lightAccent} ${styles.buttonWithArrow} ${styles.buttonWithCircles} rounded-xl relative flex justify-between w-full mb-5`}
																	onClick={(ev) => {
																		ev.preventDefault();
																		ev.stopPropagation();
																		setIsProfileShown(!isProfileShown)
																	}}>
																	<span>{isProfileShown ? "Данные профиля" : "Данные юр. лица"}</span>
																	<div className={`${styles.circleContainer} relative w-7 h-7 left-3`}>
																		<div className={styles.circle}></div>
																		<div className={styles.circle}></div>
																		<div className={styles.circle}></div>
																		<div className={styles.circle}></div>
																		<div className={styles.circle}></div>
																	</div>
																</button>
															) : <></>
														}

														{
															isProfileShown ? (
																<>
																	<div>
																		<p className='text-gray-900 mb-2'>Ваше имя:</p>
																		<Field
																			required={true}
																			placeholder='Ваше имя'
																			className='mb-3 bg-grey p-3 w-full rounded-2xl'
																			type='email'
																			Icon={AtomIcon}
																			value={name}
																			onChange={e => setName(e.target.value)}
																			full='w-full'
																			style={
																				(isFieldPreparing || name) === "" ? VALIDATE_STYLE : {}
																			}
																		/>
																	</div>

																	<div>
																		<p className='text-gray-900 mb-2'>Ваш телефон:</p>
																		<Field
																			required={true}
																			placeholder='Ваш телефон'
																			className='mb-3 bg-grey p-3 w-full rounded-2xl'
																			type='tel'
																			Icon={Phone}
																			value={phone}
																			is_confirmed={confirmedPhone === phone}
																			onChange={e => setPhone(e.target.value)}
																			full='w-full'
																			style={
																				(isFieldPreparing || phone) === "" ? VALIDATE_STYLE : {}
																			}
																		/>
																	</div>
																	<div className='flex py-5 items-center'>
																		<label for="is_phone_visible" className='text-gray-900 mr-auto' style={{cursor: 'pointer'}}>
																			Отображать телефон другим пользователям
																		</label>
																		<label>
																			<input
																				type="checkbox"
																				checked={isPhoneVisible}
																				onChange={() => setIsPhoneVisible(!isPhoneVisible)}
																				className="hidden"
																				id="is_phone_visible"
																			/>
																			<span className={`
																				w-5 h-5 flex items-center justify-center border-2 rounded 
																				${isPhoneVisible ? 'bg-accent border-accent' : 'border-gray-400'}
																			`}>
																				{isPhoneVisible && (
																				<svg
																				className="w-[18px] h-[18px] text-white"
																				fill="none"
																				stroke="currentColor"
																				strokeWidth={3}
																				viewBox="0 0 24 24"
																				>
																				<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
																				</svg>
																				)}
																			</span>
																		</label>
																	</div>

																	<div>
																		<p className='text-gray-900 mb-2'>Ваш город:</p>
																		<Field
																			required={true}
																			placeholder='Ваш город'
																			className='flex p-3 bg-grey rounded-2xl mb-3 w-full items-center justify-between placeholder:text-dark outline-none'
																			type='city'
																			clearField={()=> {
																				setCityRepresentation('')
																				setIsInputChanged(true)
																				setIsСitySelected(false)
																			}}
																			Icon={Home}
																			items={location.locations}
																			value={cityRepresentation}
																			onChange={e => {
																				setCityRepresentation(e.target.value)
																				if (e.target.value === lastSelectedCity) {
																					setIsInputChanged(false)
																					setIsСitySelected(true)
																				} else {
																					setIsInputChanged(true)
																					setIsСitySelected(false)
																				}
																			}}
																			func={(city_id, city_repr) => {
																				setCity(city_id)
																				setCityRepresentation(city_repr)
																				setLastSelectedCity(city_repr)
																				setIsСitySelected(true)
																				setIsInputChanged(false)
																			}}
																			full={`${
																				isMobileScreen  ? 'w-8/12' : 
																				isTabletScreen ? 'w-9/12' : 
																				'w-10/12'
																			}`}
																			style={cityValidateStyle}
																		/>
																	</div>

																	<div>
																		<p className='text-gray-900 mb-2'>Ваша почта:</p>
																		<label
																			className='flex mb-3 bg-grey text-[#585654] p-3 w-full rounded-2xl point-event-none'>
																			<div className='mr-3'>
																				<AtSign/>
																			</div>
																			<div>{email}</div>
																		</label>
																	</div>
																</>
															) : <></>
														}

														{
															isLegal ? (
																<>
																	{
																		!isProfileShown ? (
																			<>
																				<div>
																					<p className='text-gray-900 mb-2'>Ваш
																						ИНН:</p>
																					<Field
																						required={true}
																						placeholder='Ваш ИНН'
																						className='mb-3 bg-grey p-3 w-full rounded-2xl'
																						type='email'
																						Icon={AtomIcon}
																						value={inn}
																						onChange={e => setInn(e.target.value)}
																						full='w-full'
																						style={
																							(isFieldPreparing || inn) === "" ? VALIDATE_STYLE : {}
																						}
																					/>
																				</div>
																				<div>
																					<p className='text-gray-900 mb-2'>Ваше
																						ОГРН:</p>
																					<Field
																						required={true}
																						placeholder='Ваш ОГРН'
																						className='mb-3 bg-grey p-3 w-full rounded-2xl'
																						type='email'
																						Icon={AtomIcon}
																						value={ogrn}
																						onChange={e => setOgrn(e.target.value)}
																						full='w-full'
																						style={
																							(isFieldPreparing || ogrn) === "" ? VALIDATE_STYLE : {}
																						}
																					/>
																				</div>
																				<div>
																					<p className='text-gray-900 mb-2'>Ваш
																						юр.
																						адрес:</p>
																					<Field
																						required={true}
																						placeholder='Ваш юредический адрес'
																						className='mb-3 bg-grey p-3 w-full rounded-2xl'
																						type='email'
																						Icon={AtomIcon}
																						value={legalAddress}
																						onChange={e => setLegalAddress(e.target.value)}
																						full='w-full'
																						style={
																							(isFieldPreparing || legalAddress) === "" ? VALIDATE_STYLE : {}
																						}
																					/>
																				</div>
																				<div>
																					<p className='text-gray-900 mb-2'>Название
																						вашей
																						компании:</p>
																					<Field
																						required={true}
																						placeholder='Название вашей компании'
																						className='mb-3 bg-grey p-3 w-full rounded-2xl'
																						type='email'
																						Icon={AtomIcon}
																						value={companyName}
																						onChange={e => setCompanyName(e.target.value)}
																						full='w-full'
																						style={
																							(isFieldPreparing || companyName) === "" ? VALIDATE_STYLE : {}
																						}
																					/>
																				</div>
																			</>
																		) : <></>
																	}
																</>
															) : (
																<div className='m-2'>
																	<NavLink className='link-accent'
																			 to={BUSINESS_ROUTE}>
																		Ввести данные юр. лица можно здесь
																	</NavLink>
																</div>
															)
														}
													</form>
												</div>
											</div>
										</div>
									</div>
									<div className='bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
										<button
											type='button'
											className='inline-flex w-full justify-center rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent/90 sm:ml-3 sm:w-auto'
											onClick={async (ev) => {
												setIsFieldPreparing(false)
												if (phone !== confirmedPhone && isProfileShown) {
													await smsVerification(ev);
												} else {
													await updateProfile(null);
												}
											}}>
											Обновить
										</button>
										<button
											type='button'
											className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
											onClick={() => {
												setIsFieldPreparing(true)
												onHide(false)
											}}
											ref={cancelButtonRef}>
											Закрыть
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
					<PhoneVerification
						open={verificationShow}
						setOpen={setVerificationShow}
						verificationId={verificationId}
						handleRegister={updateProfile}
					/>
				</Dialog>
			</Transition.Root>

			<ErrorAlert
				open={errorAlertShow}
				setOpen={setErrorAlertShow}
				title={alertTitle}
				status={errorStatus}
				message={alertMessage}
			/>
			<AccessAlert
				open={accessAlertShow}
				setOpen={setAccessAlertShow}
				alertTitle={alertTitle}
				alertMessage={alertMessage}
			/>
		</>
	)
})

export default ProfileUser
